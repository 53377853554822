import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import transition from "../utlities.js";
import { images } from "./all_images.js";
import PROJECTS from '../projectOverviews.json';

const Project = ({ thumbnail, id, year, title, summary }) => {
  return (
    <div className="project">
      <div 
        className="project-content"
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.9)),
          url(${thumbnail})`
        }}
        >
        <Link to={`/project/${id}`}>
          <div 
            className="project-desc"
            >
            <p className="project-desc_year"> {year}</p>
            <h3 className="project-desc_title"> {title}</h3>
            <p className="project-desc_summary">{summary}</p>
          </div>
        </Link>
      </div>
    </div>
  );
}

const Portfolio = ({ showButton }) => {
  useEffect(() => showButton(), []);

  const projects = Object.entries(PROJECTS).map(([id, data], i) => (
    <Project
      key={i}
      id={id}
      title={id.replaceAll('_', ' ')}
      year={data.timeline}
      thumbnail={images[data.thumbnail]}
      summary={data.tagline}
    />
  ));
  
  return (
    <div className="portfolio">
      <div className={`portfolio-content ${transition("bottom", 1)}`}>
        {projects}
      </div>
    </div>
  )
};

export default Portfolio;
