import React from "react";
import Sidebar from "./Sidebar.js";
import Portfolio from "./Portfolio.js";

const Landing = ({ showButton }) => {
  return (
    <div path="/" className="container">
      <Sidebar />
      <Portfolio showButton={showButton} />
    </div>
  );
}

export default Landing;
