import React, { useEffect } from "react";
import { Redirect, useParams } from 'react-router-dom';
import transition from "../utlities.js";
import OVERVIEWS from "../projectOverviews.json";
import BODY from "../projectPages.json";
import { images } from "./all_images.js";


const ProjectPage = ({ showButton = () => {} }) => {
  useEffect(() => showButton(), []);
  
  const { projectId } = useParams();

  // Viewiing a project that does not exist
  if (!OVERVIEWS[projectId]) {
    return <Redirect to='/' />
  };

  const overview = OVERVIEWS[projectId];
  const body = BODY[projectId];

  const makeLinks = (links) => (
    links.map((link, i) => (
      <a
        key={i}
        className='link project-link'
        href={link.url}
        target='_blank'
      >
        {link.caption}
      </a>
    ))
  );
  
  const links = overview.links && (
    <div className='project-page_intro-links'>
      <span role='img' aria-label='links'>
        &#128279;
      </span> 
      &nbsp;{makeLinks(overview.links)}
    </div>
  );

  const tags = overview.tags && (
    <div className='project-page_intro-tags'>
      {overview.tags
        .split(', ')
        .map((tag, i) => 
          <span className='tag'>
            {tag}
          </span>
        )
      }
    </div>
  );

  const intro = (
    <section className='project-page_intro'>
      <h1 className="project-page_intro-title">
        {projectId.replaceAll('_', ' ')}
      </h1>
      <div className='project-page_intro-info'>
        <p className="project-page_intro-summary">{overview.summary}</p>
        {links}
        {tags}
      </div>
      {overview.image && (
        <>
          <img src={images[overview.image.url]}/>
          <p className='caption'>
            {overview.image.caption}
          </p>
        </>
      )}
    </section>
  );

  return (
    <div className={`project-page ${transition("bottom",0)}`}>
        {intro}
        <h3>Page is still a work in progress! </h3>
    </div>
  );
}

export default ProjectPage;
