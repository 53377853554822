import React, { useState } from "react";
import Landing from "./Landing.js";
import Header from "./Header.js";
import ProjectPage from "./ProjectPage.js";
import '../styles/app.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

const App = () => {
  let [ showButton, setShowButton ] = useState(false);
  
  return (
    <Router>
      <Header showButton={showButton}/>
      <Switch>
        <Route path='/project/:projectId'>
          <ProjectPage showButton={() => setShowButton(true)} />
        </Route>
        <Route path='/'>
          <Landing showButton={() => setShowButton(false)} />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
