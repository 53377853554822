import React from "react";
import { Link } from "react-router-dom";
import transition from "../utlities.js";

import logo from "../thumbnails/personal_logo.svg"


const Header = ({ showButton }) => {
  return(
    <div className="header">
      <img 
        className={`header-content ${showButton && 'hide'}`}
        draggable={false}
        height="30px" 
        width="30px" 
        src={logo}
      />
      {showButton && 
        <Link to="/">
          <div className={`back-btn ${transition('right', 0)}`} href="/">
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              class="icon icon-tabler icon-tabler-arrow-left" 
              width="16" 
              height="16" 
              viewBox="0 0 20 20" 
              stroke-width="2.5" 
              stroke="#ffffff" 
              fill="none" 
              stroke-linecap="round" 
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <line x1="5" y1="12" x2="19" y2="12" />
              <line x1="5" y1="12" x2="11" y2="18" />
              <line x1="5" y1="12" x2="11" y2="6" />
            </svg>
            &nbsp;back&nbsp;
            <span className='back-btn_text'>to portfolio</span>
          </div>
        </Link>
      }
    </div>
  );
}

export default Header;