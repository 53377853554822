import React from 'react';
import mail from '../thumbnails/mail_basic.svg';
import linkedin from '../thumbnails/linkedin_basic.svg';
import github from '../thumbnails/github_basic.svg';
import transition from '../utlities';


// Component for displaying social medias/other form of contact
const Contact = () => {
    const LOGO_SIZE = '25px';

    return (
        <div className={`contacts ${transition('bottom', 2)}`}>
            <a href='https://github.com/amy-lei/' target='_blank'>
                <img 
                    className='logo'
                    height={LOGO_SIZE}
                    width={LOGO_SIZE}
                    src={github} 
                    alt='github' 
                />
            </a>
            <a href='https://linkedin.com/in/lei-amy' target='_blank'>
                <img 
                    className='logo'
                    height={LOGO_SIZE}
                    width={LOGO_SIZE}
                    src={linkedin} 
                    alt='linkedin' 
                />
            </a>
            <a href='mailto:leiamy12@gmail.com' target='_blank'>
                <img 
                    className='logo'
                    height={LOGO_SIZE}
                    width={LOGO_SIZE}
                    src={mail} 
                    alt='email'
                />
            </a>
        </div>
    );
};

export default Contact;