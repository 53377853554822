import React from "react";
import transition from "../utlities.js";
import Contact from './Contact';

const Sidebar = () => {
  return (
    <aside className="sidebar">
      <div className="sidebar-content">
        <h1 className={`sidebar-name ${transition("left",0)}`}>
          amy lei.
        </h1>
        <p className={`sidebar-about ${transition("left",1)}`}> 
          Hello there! I am a student studying computer science (course 6-3) at MIT. 
          In my spare time, I love to create designs, learn new skills, and contribute to open source projects such as
          Jinja and Click from the <a href='https://palletsprojects.com/' target='_blank' className='link'>
          Pallets Projects</a>.
        </p>
        <div className="sidebar-cur">
          <div className='sidebar-cur_wrapper'>
            <h3 className={`sidebar-cur_header heading ${transition("left",2)}`}>
              currently
            </h3>
            <p className={`sidebar-cur_content ${transition("left",2)}`}>
              <a href='https://buildyourfuture.withgoogle.com/programs/step/' target='_blank' className='link'>
                STEP Intern
              </a> @ Google
            </p>
          </div>
          <div className='sidebar-cur_wrapper'>
            <h3 className={`sidebar-cur_header heading ${transition("left",2)}`}>
              last watched 
            </h3>
            <p className={`sidebar-cur_content ${transition("left",2)}`}>
              <a className='link' href="https://www.imdb.com/title/tt0119217/" target="_blank">Good Will Hunting</a>
            </p>
          </div>
        </div>
        <Contact />
      </div>
    </aside>
  );
}

export default Sidebar;